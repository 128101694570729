import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

function builder(data) {
  return new Vuex.Store({
    state: {
      content: data,
    },
    mutations: {
      increment(state) {
        state.count++;
      },
    },
    actions: {},
    modules: {},
    getters: {
      content() {
        return this.content;
      },
    },
  });
}

export default builder;
