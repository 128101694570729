<template>
  <div class="home container mx-auto bg-white shadow-sm p-0 mb-5 bg-white">
    <!--image-->
    <div class="heading row bg-primary m-0 p-0 py-4">
      <div class="col-xs-12 col-md-5 text-center">
        <img
          src="sabin.jpg"
          alt=""
          class="p-4 rounded-circle rounded"
          width="300"
          height="300"
          id="profile"
        />
      </div>
      <div
        class="col-xs-12 col-md-6 d-flex flex-column justify-content-center align-items-center"
      >
        <div class="text-center">
          <div class="h1 fw-bold">
            <span>S</span>
            <span>A</span>
            <span>B</span>
            <span>I</span>
            <span>N</span>
            <span>&nbsp;</span>
            <span>K</span>
            <span>H</span>
            <span>A</span>
            <span>N</span>
            <span>A</span>
            <span>L</span>
          </div>
          <div class="h5 subheading">Full Stack Developer</div>
        </div>

        <div class="pt-5 social-icons d-flex flex-row gap-3">
          <a href="https://www.facebook.com/sabinn.khanal">
            <i class="fab fa-facebook fa-2x text-white"></i
          ></a>
          <a href="https://twitter.com/programmersabin">
            <i class="fab fa-twitter fa-2x text-white" />
          </a>
          <a href="https://www.instagram.com/programmersabin/">
            <i class="fab fa-instagram fa-2x text-white"></i
          ></a>
          <a href="https://github.com/sabine33"
            ><i class="fab fa-github fa-2x text-white"></i>
          </a>
          <a href="https://www.linkedin.com/in/sabin-khanal-a256b5103/"
            ><i class="fab fa-linkedin fa-2x text-white"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="about row bg-white py-5 mx-0 px-0">
      <div class="col-xs-12 col-md-12 col-lg-6">
        <div class="d-flex flex-column align-items-start px-4">
          <h3>About <span>Me</span></h3>
          <p>
            {{ about }}
          </p>
          <div class="d-flex gap-3">
            <a href="cv.pdf" type="button" class="btn btn-outline-primary">
              Download CV
            </a>
            <router-link to="/portfolio" type="button" class="btn btn-danger">
              <i class="fa fa-arrow-right"></i>
              View Portfolio
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-4 px-4 mx-4 px-sm-0 px-mx-0">
        <table class="table table-hover table-borderless">
          <tbody>
            <tr>
              <td>Age</td>
              <td>27</td>
            </tr>
            <tr>
              <td>Residence</td>
              <td>Nayabazar,16 , Kathmandu</td>
            </tr>
            <tr>
              <td>Permanent Address</td>
              <td>Gorkha, Shaheed Lakhan 7</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>sabin.khanal.33@gmail.com</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>9863XXXXXX</td>
            </tr>
            <tr>
              <td>Freelance</td>
              <td>Available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
  h1 {
    font-size: 2em;
    color: #fff;
    font-weight: bold;
  }
  h2 {
    font-size: 1.2em;
    color: #fff;
  }
  table {
    text-align: justify;
  }
  p {
    text-align: justify;
  }
  .subheading {
    color: #ddd;
  }
  .social-icons i:hover {
    transform: scale(1.3);
  }
  #profile {
    box-shadow: 0 3px 3px 0px #ddd;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  #profile:hover {
    transform: rotate(360deg);
  }
  .h1 span {
    color: #fff !important;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.1em;
  }
  span:hover {
    transform: scale(1.4);
    color: #888;
  }
</style>
<script>
  export default {
    name: "Home",
    components: {},
    computed: {
      about() {
        return this.$store.state.content.about;
      },
    },
  };
</script>
