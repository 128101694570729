<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-white shadow-sm p-3 mb-5 bg-white rounded"
  >
    <div class="container">
      <router-link class="navbar-brand" to="/"
        ><h2>Sabin <span>Khanal</span></h2>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        ref="navbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-4">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/"
              >Home</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link active"
              aria-current="page"
              to="/resume"
              >Resume</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <router-link
              class="nav-link active"
              aria-current="page"
              to="/services"
              >Services</router-link
            >
            <!-- <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul> -->
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/portfolio"
              >Portfolio</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/blog"
              >Blog</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/contact"
              >Contact</router-link
            >
          </li>
        </ul>
        <!-- <form class="d-flex">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>
<style scoped>
  ul li a {
    transition: border cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  }
  ul li a:hover {
    border-bottom: 2px solid #ddd;
  }
  #navbarSupportedContent {
    transition: all 0.5s ease-in;
  }
  .router-link-active {
    font-weight: bold;
  }
  .collapse {
    transition: all 0.5s ease-out;
  }
</style>
<script>
  export default {
    data() {
      return {
        navExpanded: false,
      };
    },
    watch: {
      $route(to, from) {
        // if (window.outerWidth < 600) {
        if (to.path == from.path || to.path == "/") {
          this.$refs.navbar.click();
        }
        if (this.$route.path !== "/") {
          this.$refs.navbar.click();
        }
        // }

        //click on route change
      },
    },
  };
</script>
