<template>
    <div id="app">
        <NavbarVue />

        <div class="py-2 h5 text-bold container text-center p-0 mx-auto">
            <div class="alert alert-primary" role="alert">
                <!-- <strong>
          Some parts of the website is still under construction. Sorry for
          inconvenience.</strong
        > -->
                <!-- <h1>Hello</h1> -->
            </div>
        </div>
        <vue-page-transition name="fade-in-right">
            <router-view
        /></vue-page-transition>
    </div>
</template>
<script>
import NavbarVue from "./components/Navbar.vue";

export default {
    components: { NavbarVue },
};
</script>
<style>
body {
    background-color: #f9f9f9;
}
#app {
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    margin: 0px auto;
}

h1 {
    color: #fff;
}
span {
    color: #0d6efd;
    font-weight: 600;
}
.block {
    margin: 30px auto;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
.page {
    padding-bottom: 50px !important;
}
.h3.title {
    text-align: center;
}
@media screen and (max-width: 1024px) {
    .row {
        gap: 30px;
    }
}
i {
    /* color: #0d6efd; */
}
</style>
