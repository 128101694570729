import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueResource from "vue-resource";

Vue.config.productionTip = false;

import VuePageTransition from "vue-page-transition";

Vue.use(VuePageTransition);

fetch("content.json")
  .then((response) => response.json())
  .then((data) => {
    console.log(data);
    /* eslint-disable no-new */
    new Vue({
      router,
      store: store(data),
      render: (h) => h(App),
    }).$mount("#app");
  });
