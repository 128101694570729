import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  { path: "*", redirect: "/" }, // catch all use case

  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { transition: "zoom" },
  },
  {
    path: "/resume",
    name: "Resume",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Resume.vue"),
    meta: { transition: "overlay-up-down" },
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue"),
    meta: { transition: "overlay-left-full" },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("../views/Portfolio.vue"),
    meta: { transition: "overlay-up-full" },
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog.vue"),
    meta: { transition: "overlay-right-full" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: { transition: "overlay-down-full" },
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
